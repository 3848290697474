.footer-container {
  background-attachment:inherit;
  background-color: #127c71;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  margin: 0 0;
}

.contactinfo {
  color: #0a142f;
  border-color: #0a142f;
  flex-direction: column;
  width:30%;  
}
.contactinfo a{
  color: #0a142f;
}

.logo-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  color: #2c3e50;
  white-space: nowrap;
  width: 133px;
  margin: auto 0;
  font: 400 40px Saira Stencil One, -apple-system, Roboto, Helvetica, sans-serif;
}

.logo-image {
  aspect-ratio: 1.05;
  object-fit: contain;
  object-position: center;
  width: 133px;
  margin-left: 35%;
}
.logimg{
  justify-content: center;
  width: 35%;
  margin-top: 34px;
}
.logimg h2{
  color: #0a142f;
}

.Insa{
  margin-right: 10%;
  width: 35%;
}
.Insa h2{
  color: #0a142f;
}

.Insa h6{
  font-size: medium;
  color: rgb(180, 180, 180);
  
}
.icon {
  aspect-ratio: 1.04;
  object-fit: contain;
  object-position: center;
  width: 25px;
}

.phone-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
  margin: auto 0;
}
.social-icons {
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 1rem;
}

.social-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
}

.footer-bottom {
  display: flex;
  margin-top: 25px;
  width: 100%;
  flex-direction: column;
  
  color: #0a142f;
  justify-content: start;
  font: 400 14px Assistant, sans-serif;
}

.footer-line {
  background-color: #103607de;
  min-height: 1px;
  width: 100%;
}

.footer-links {
  display: flex;
  margin-top: 24px;
  width: 100%;
  align-items: center;
  gap: 40px 100px;
  justify-content: center;
  flex-wrap: wrap;
}

.nav-links {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 40px 50px;
  text-transform: uppercase;
  justify-content: start;
  flex-wrap: wrap;
  margin: auto 0;
}

.nav-link {
  align-self: stretch;
  margin: auto 0;
}

a{
  font-weight:600;
  color: #858585;
}

.copyright {
  opacity: 0.5;
  align-self: stretch;
  margin: auto 0;
  justify-content: center;
}
.sidebside{
  
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
}
@media (max-width: 600px) {
  .Insa{
    display: none; 
  }
  .contactinfo{
    margin-top:20px
  }
 

}
@media (min-width: 601px) and (max-width: 1024px){
  
}