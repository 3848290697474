.file-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff2cc;
  scroll-behavior: smooth;
}

.file-table th, .file-table td {
  color: black;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  background-color: white;
  border-bottom: 1px solid #f5d78c;
}

.file-table thead th {
  font-size: 18px;
  background-color: #fff2cc;
  font-weight: bold;
}
.file-table td {
  font-size: 16px;
}
h2{
  font-size: 35px;
}
h3{
  text-align: center;
}
.actions {
  display: flex;
  gap: 10px;
  font-size: 18px;
}

.actions span {
  cursor: pointer;
}
