.header {
  background-color: #a5c2a446;
  padding: 0.2rem 0;
  margin-bottom: 0;
  box-shadow: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10; 
}

.logo {
  height: 3rem;
  width: 15rem;
  
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}

.nav {
  display: flex;
  justify-content: space-between;
  color: #050c03bd;
  font-family: Arial, sans-serif;
  align-items: center;
  padding: 1rem 0 0 0;
}

.nav a {
  color: #050c03bd;
  margin: 0 0.5rem;
  text-decoration: none;
}

.btn-start {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  width: 6.5rem;
  height: 2.5rem;
  background-color: #127c71;
  margin: 0 0 0 2rem;
}
.hamburger {
  margin-left:auto;
  display: none; 
  flex-direction: column;
  justify-content: space-between;
  height: 25px;
  z-index: 1000; 
}
.hamburger .line {
  background-color: black; 
  height: 3px;
  width: 25px;
  border-radius: 2px;
}
.nav.mobile {
  display: none; 
  flex-direction: column;
  background-color: #333;
  position: absolute;
  backdrop-filter: blur(15px); 
  background-color: rgba(0, 0, 0, 0.6); 
  top: 60px; 
  right: 0;
  width: 100%; 
  z-index: 1000;
  color: white;
  padding: 15px 0;
}
.nav.mobile a {
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  text-align: center;
}
.nav.mobile a:hover {
  background-color: #575757;
}
.nav.open {
  display: flex; 
}
 /* @media screen and (min-width: 601px) and (max-width: 1024px) {
  .nav {
    font-size: 14px; 
  } */

  /* img{
    width:20px;
  }
  .btn-start{
    width:20px;
    margin-left:0px;
  }
} */

/* Mobile Devices */
/* @media screen and (max-width: 600px) {
  .nav {
    display:flex;;
    font-size: 10px; 
  }
  .btn-start{
    width:20px;
    margin-left:0px;
  }
}  */
/* @media (max-width: 1024px) {
  .nav {
    gap: 15px;
  }

  .nav a {
    font-size: 0.9rem;
  } */
/* 
  .nav .btn-start {
    padding: 6px 12px;
  }
} */

/* Mobile (max-width: 600px) */
@media (max-width: 600px) {
  .nav {
    display: none; 
  }

  .hamburger {
    display:flex; 
  }

  .nav.mobile {
    display: inline-grid; 
  }
}
@media (min-width: 601px) and (max-width: 1024px){
  .nav{
      
  }
}
