.admin_main-content {
  background-color: #e0e4deef;
  font-family: 'Arial', sans-serif;
  padding: 1rem 3rem 0 0;
}



.user-info{
  align-items: center;
  display: flex;
  flex-direction: column;
}

.navbar .user-info {
  display: flex;
  align-items: center;
}

.navbar .user-info img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.navbar .user-info span {
  font-size: 16px;
  color: #333;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dashboard-header h4{
  font-size: 24px;
  color: #000;
}

.dashboard-header .search-bar {
  display: flex;
  align-items: center;
}

.dashboard-header .search-bar input {
  padding: 10px;
  width: 250px;
  border-radius: 20px;
  border: 1px solid #ddd;
  margin-right: 10px;
}
.dltbtn{
  background-color: #0f3813;
}
.add-user-btn1 {
  background-color: #103607de;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.users-list {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.users-list table {
  width: 100%;
  border-collapse: collapse;
}

.users-list th, .users-list td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.users-list th {
  background-color: #f7f7f9;
  color: #333;
}

.users-list td {
  color: #555;
}

.users-list .role {
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  color: #fff;
}
small{
  color: #333;
}
.users-list .role.super-admin {
  background-color: #103607de;
}

.users-list .role.admin {
  background-color: #103607de;
}

.users-list .role.hr-admin {
  background-color: #03a9f4;
}

.users-list .role.employee {
  background-color: #cfd8dc;
  color: #555;
}

.users-list .action-icons {
  display: flex;
  align-items: center;
}

.users-list .action-icons i {
  margin-right: 10px;
  cursor: pointer;
}

.users-list .pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.users-list .pagination span {
  margin: 0 5px;
  cursor: pointer;
  color: #555;
}

.users-list .pagination span.active {
  font-weight: bold;
  color: #000;
}



/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Container */
.modal-container {
  background: white;
  padding: 3rem 7rem;
  border-radius: 8px;
  width: 600px;
  max-width: 100%;
}

.modal-container h2 {
  margin-bottom: 20px;
}

/* Form Fields */



/* Form Row for Side-by-Side Inputs */
.form-row {
  display: flex;
  /* gap: 20px; */
}

.form-aduser {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap:2px;
}
.form-aduser label{
  margin-left: 0px;
  text-align: start;
  margin: 4px;
}
.form-aduser input{
  display: block;
  align-items: start;
  justify-content: start;
  margin-left: 4px;
}
.form-aduser input[type="text"]{
  width: 480px;

}
.form-aduser input[type="email"]{
  width: 480px;
}
.form-aduser #role{
  width: 504px;
}
.form-aduser #password1{
  width: 850px;
}
.form-aduser input[type="password"]{
  
  width: 950px;
}
/* Form Actions */
.form-actions {
  display: flex;
  justify-content: space-between;
}

.form-actions button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
#errorsta{
  color:red;
}
.subbtn {
  background-color: #000;
  color: #eee;
}

.form-actions button[type="button"] {
  background-color: gray;
  color: white;
}
@media (max-width: 575px){
  .form-aduser input[type="text"]{
    width: 380px;
  
  }
  .form-aduser input[type="email"]{
    width: 370px;
  }
  .form-aduser #role{
    width: 390px;
  }
  .form-aduser #phone1{
    width: 370px;
  }
  .form-aduser input[type="password"]{
    width: 404px;
  }
}
@media (min-width: 768px) and (max-width: 991px)  {
  .form-aduser input[type="text"]{
    width: 580px;
  
  }
  .form-aduser input[type="email"]{
    width: 580px;
  }
  .form-aduser #role{
    width: 504px;
  }
  .form-aduser #password1{
    width: 850px;
  }
  .form-aduser input[type="password"]{
    width: 504px;
  }
}