.approver_search-bar {
  display: flex;
  background-color: #e0e4deef;
  align-items: center;
  margin-bottom: 20px;
}

.approver_search-bar input[type="text"] {
  width: 300px;
  padding: 8px 10px;
  font-size: 14px;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  margin-right: 10px;
}

.approver_search-bar .sort-by {
  margin-right: 3rem;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.approver_search-bar .sort-by select {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  margin-left: 10px;
  background-color: #FFFFFF;
  cursor: pointer;
}

.document-list {
  background-color:  #F6F6F6;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.document-list h2 {
  margin: 0;
  font-size: 20px;
  color: #333333;
  margin-bottom: 20px;
}

.document-list table {
  width: 100%;
  border-collapse: collapse;
}

.document-list table th, .document-list table td {
  padding: 10px;
  border-bottom: 1px solid #E0E0E0;
  text-align: left;
  font-size: 14px;
}

.document-list table td img {
  width: 40px;
  margin-right: 10px;
  vertical-align: middle;
}

.document-list table td .status {
  padding: 5px 10px;
  border-radius: 20px;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.document-list table td .status.paid {
  background-color: #e0e4deef;
}

.document-list table td .status.pending {
  background-color: #e0e4deef;
}

.document-list table td .status.expired {
  background-color: #e0e4deef;
}

.document-list table td .actions {
  display: flex;
  align-items: center;
}

.document-list table td .actions button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
}
.approver_content {
  flex-direction: column;
}
.approver_content img{
  display: block;
  margin: 0 auto;
  width: 67px;
  padding-bottom: 20px;
}
.document-list table td .actions button i {
  font-size: 16px;
  color: #f0ede9;
}
.document-list button
{
  background-color: #103607de;
}

.sort-by button{
  background-color: #103607de;
}