
.services-section {
  display: flex;
  background-color: #f7fafc;
  padding: 2rem 0;
}

.services-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  text-align: center;
}

.services h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #333;
}


.services .slider {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.slider-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: opacity 0.5s ease;
}


.service-slide-content {
  display: flex;
  align-items: stretch; 
  justify-content: space-between;
  gap: 2rem;
  flex-direction: row; 
  padding: 2rem;
  width: 100%;
}


.service-img-container {
  flex: 1;
  max-width: 45%;
  display: flex;
  justify-content: center; 
}

.service-img {
  width: 75%;  
  height: 75%;  
  object-fit: cover;  
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.service-content-container {
  flex: 1;
  max-width: 45%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  background-color: #fff;
  padding: 1.5rem;  
  border-radius: 8px;
  /*box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);*/
}

.service-details h3 {
  font-size: 2.0rem;
  color: #3b3b3b;
  margin-bottom: 0.5rem; 
}

.service-details p {
  font-size: 1.5rem;
  color: #555;
  line-height: 1.5;
  margin-top: 0.5rem; 
}


@media (max-width: 768px) {
  .services h1 {
    font-size: 2rem;
  }

  .service-slide-content {
    flex-direction: column; 
    gap: 1rem;
  }

  .service-img-container {
    max-width: 100%;
  }

  .service-content-container {
    max-width: 100%;
    padding: 1rem;
  }

  .service-details h3 {
    font-size: 1.50rem;
  }

  .service-details p {
    font-size: 1.0rem;
  }
}
