.how-it-works-section {
  display: flex;
  /* background-image: url("../../assets/curved_bg_light.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  /* background-color: #12354d; */
  background-attachment:inherit;
  background-color:#e0e4deef;
  margin-bottom: 5px;
  text-align: center;
}
.howItWorks_container {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.howItWorks_container h1 {
  color: #127c71;
  font-size: 3rem;
}

.video-container {
  height: 25rem;
  width: 55rem;
  color: #127c71;
  font-size: 30rem;
  font-style: oblique;
  background-image: url("../../assets/curved_grey_r_light.png");
  background-size:auto;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container iframe {
  margin-bottom: 1rem;
  border-radius: 4rem;
  width: 35rem;
  height: 20rem;
}
@media (max-width: 768px) {
  .howItWorks_container h1 {
    font-size: 2rem;
  }
  
  .video-container {
    height: 18rem;
    width: 80%;
  }
}


@media (max-width: 480px) {
  .howItWorks_container h1 {
    font-size: 1.5rem;
  }
  
  .video-container {
    height: 12rem;
    width: 90%;
    font-size: 1.5rem; 
    padding-top: 10px;
  }
}