.admin_main_container{
  background-color: #e0e4deef;
  font-family: sans-serif;
  overflow: hidden;
}

.admin_navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  padding: 0 4rem 0 0;
}

.admin_navbar .welcome {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}
.lgoutbtn {
  background-color: #0d2b09;
}
.admin_navbar img{
  width:3rem;
  height:3rem;
  border-radius: 100%;
}
.admin_main_container{
  width: 100%;
  display: flex;
  flex-direction: row;
}
.sidebar {
  width: 220px;
  background-color: #ffffff;
  position: fixed;
  height: 100%;
  padding-top: 20px;
  border-right: 1px solid #e3e3e3;
}

.sidebar img {
  display: block;
  margin: 0 auto;
  width: 80%;
  padding-bottom: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  padding: 15px 20px;
  font-size: 16px;
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sidebar ul li:hover {
  background-color:#38722bde;
}

.sidebar ul li.active {
  background-color: #103607de;
  color: #fff;
  border-left: 5px solid #103607de;
}

.sidebar ul li i {
  margin-right: 15px;
  color: #103607de;
}

.admin_content{
  width: 100%;
  margin-left: 15rem;
}
@media (max-width: 575px) {
  .sidebar{
    width:130px;
  }
}


@media (min-width: 576px) and (max-width: 767px) {
  .sidebar{
    width:140px;
  }
}

/* Large Tablets or Small Laptops (768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .sidebar{
    width:170px;
  }
}