.admin_main-content {
    font-family: 'Arial', sans-serif;
    padding: 1rem 3rem 0 0;
  }
  
  
  
  .user-info{
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .navbar .user-info {
    display: flex;
    align-items: center;
  }
  
  .navbar .user-info img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .navbar .user-info span {
    font-size: 16px;
    color: #333;
  }
  
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .dashboard-header h1 {
    font-size: 24px;
    color: #f7b400;
  }
  
  .dashboard-header .search-bar {
    display: flex;
    align-items: center;
  }
  
  .dashboard-header .search-bar input {
    padding: 10px;
    width: 250px;
    border-radius: 20px;
    border: 1px solid #ddd;
    margin-right: 10px;
  }
  
  .dashboard-header .add-user-btn {
    background-color: #f7b400;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .users-list {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .users-list table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .users-list th, .users-list td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
  
  .users-list th {
    background-color: #f7f7f9;
    color: #333;
  }
  
  .users-list td {
    color: #555;
  }
  
  .users-list .role {
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 14px;
    color: #fff;
  }
  
  .users-list .role.super-admin {
    background-color: #f7b400;
  }
  
  .users-list .role.admin {
    background-color: #ff9800;
  }
  
  .users-list .role.hr-admin {
    background-color: #03a9f4;
  }
  
  .users-list .role.employee {
    background-color: #cfd8dc;
    color: #555;
  }
  
  .users-list .action-icons {
    display: flex;
    align-items: center;
  }
  
  .users-list .action-icons i {
    margin-right: 10px;
    cursor: pointer;
  }
  
  .users-list .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
  }
  
  .users-list .pagination span {
    margin: 0 5px;
    cursor: pointer;
    color: #555;
  }
  
  .users-list .pagination span.active {
    font-weight: bold;
    color: #000;
  }
  
  
  
  /* Modal Overlay */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal Container */
  .modal-container {
    background: white;
    padding: 3rem 7rem;
    border-radius: 8px;
    width: 600px;
    max-width: 100%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .modal-container h2 {
    margin-bottom: 20px;
  }
  
  /* Form Fields */
  .modal-container form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .modal-container form input,
  .modal-container form select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Form Row for Side-by-Side Inputs */
  .form-row {
    display: flex;
    /* gap: 20px; */
  }
  
  .form-group {
    flex: 1;
  }
  
  /* Form Actions */
  .form-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .form-actions button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-actions button[type="submit"] {
    background-color: orange;
    color: white;
  }
  
  .form-actions button[type="button"] {
    background-color: gray;
    color: white;
  }
  