.describe-section {
  background-attachment: inherit;
  background-color: #127c71;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-top:20px;
}

.describe_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: justify;
  margin: 1rem;
  gap: 1rem;
}

.describe_moto {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.describe_moto h3 {
  color: rgb(114, 146, 106);
  font-size: 2.5rem;
}

.describe_moto p{
  font-size: 1.6rem;
  font-weight: 500;
  color: #e8eee7f3;
  margin-left:5px ;
  text-align: left;
}
.describe_moto h1{
  color:lch;
  font-size:2.5rem;
}

.btn-getStarted {
  margin-top: 1rem;
  height: 4rem;
  width: 17rem;
  background-color: #103607de;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
}
.video1 {
  
    margin-top: 5rem;
    margin-bottom: 2rem;
    margin-left: 5rem;
    margin-right: 1rem;
    border: 2px solid #acafacde;
    border-radius: 34px;
    width: 750px;
    height:400px;  
}

.video {
  position: fixed; 
  inset: 0px; 
  width: 100%; 
  height: 100%; 
  object-fit: cover;
  z-index: -1; 
  filter: blur(0px);
}

@media (max-width: 1024px) {
  .describe_moto h1, .describe_moto h3 {
    font-size: 2rem;
  }
  
  .video1 {
    width: 600px;
    height: 350px;
  }

  .btn-getStarted {
    height: 3.5rem;
    width: 10rem;
    font-size: 1.3rem;
  }
}

@media (max-width: 768px) {
  .describe_container {
    flex-direction: column;
    margin-left: 0;
  }

  .describe_moto h1, .describe_moto h3 {
    font-size: 1.8rem;
  }

  .describe_moto p, .describe_moto ul {
    font-size: 1.1rem;
  }

  .video1 {
    width: 500px;
    height: 300px;
  }

  .btn-getStarted {
    height: 3.5rem;
    width: 9rem;
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .describe_moto h1, .describe_moto h3 {
    font-size: 1.5rem;
  }

  .describe_moto p, .describe_moto ul {
    font-size: 1rem;
  }

  .video1 {
    width: 90%;
    height: auto;
  }

  .btn-getStarted {
    height: 3.5rem;
    width: 8rem;
    font-size: 1rem;
  }
}