.bodyc-login {
  font-family: "Helvetica Neue", Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  position: relative;
}

.heroc-section {
  background-color: #e0e4deef;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  margin-top: 0;
}

.containerc-login {
  display: flex;
  max-width: 900px;
  width: 400px;
  height: 500px;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  position: relative;
  pointer-events: none; /* Disable pointer events on the entire container */
}
.logoc-login {
  margin-bottom: 20px;
}

.logoc-login img {
  display: block;
  width: 60px;
  height: auto;
}

.leftc-section {
  flex: 1;
  pointer-events: auto; /* Enable pointer events only on sections with interactive content */
}
 

  
  .formc-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
 
  .formc-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .leftc {
    width: 88%;
  }
  
  label {
    display: block;
    text-align: start;
    font-weight: bold;
    margin-bottom: 4px;
    color: #999;
    margin-left: 15px;
  }
  
  
  #email
  {
    width: 340px;
    padding: 12px;
    border: 1px solid #333;
    border-radius: 6px;
    font-size: 12px;
  }
 
  
  small {
    font-size: 10px;
    color: #888;
    margin-top: 4px;
    display: block;
  }
  
 
  
  .submit-button {
    width: 250px;
    padding: 12px;
    background-color: #266823;
    color: #fff;
    border: none;
    border-radius: 15px;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    margin-top: 20px;
  }
  
  