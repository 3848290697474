body_paymentSection {
  font-family: 'DM Sans', sans-serif;
  background-color: white;
  margin: 0;
  padding: 0;
  padding-top: 60px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.form-title {
  color: #0a0d13;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 10px;
  color: #666666;
  font-size: 14px;
  font-weight: 400;
}

.form-select {
  width: 100%; /* Restored to full width */
  height: 60px; /* Restored original height */
  padding: 0 20px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 18px; /* Restored font size */
  background-color: #fff;
  background-image: url('https://cdn.builder.io/api/v1/image/assets/TEMP/9c09303953440b215d9e255075828c2730ac4960c1c8f3866bed0142df638a0c?placeholderIfAbsent=true&apiKey=854e471005c746ca9085e9d4115268fb');
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 18px;
  appearance: none;
}

.submit-button {
  width: 50%; /* Adjusted button width to original size */
  padding: 15px; /* Restored original padding */
  background-color: #f69205;
  color: #ffffff;
  font-size: 18px; /* Restored original font size */
  font-weight: 700;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}