
.main_container_profile{
  width: 100%;
  overflow: hidden;
  float: left;
  background-color: #e7ece7;
}

.settings-main {
  display: flex;
  width: 80%;
  
  flex-direction: column;
  
}
.profile-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.profile-header h1 {
  font-size: 24px; /* Main heading size */
  font-weight: bold;
}

.profile-form label {
  display: flex;
  font-size: 14px; /* Label size */
  margin-bottom: 20px;
  
  color: #333;
}


.edit-btn2{
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background-color: #0a2c10;
  width: 150px;
  margin-left: 1050px;
}


.hidden {
  display: none;
}
.file-table1 {
  width: 80%;
  border-collapse: collapse;
  
}

.file-table1 .edit-btn3{
  background-color:#062b06 ;
  
}
.file-table1 th, .file-table1 td {
  color: black;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  
  border-bottom: 1px solid #f5d78c;
}

.file-table1 thead th {
  
  font-weight: bold;
}
.personal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Container */
.personal-container {
  background: white;
  padding: 3rem 7rem;
  border-radius: 8px;
  width: 600px;
  max-width: 100%;
}
.profile-group label{
  margin-left: 0px;
  text-align: start;
  margin: 4px;
}
.profile-group input{
  display: block;
  align-items: start;
  justify-content: start;
  margin-left: 4px;
  height: 25px;
  padding: 3px;
  border-radius: 3px;
}
.profile-group input[type='text']{
  width: 580px;
}
.profile-group input[type='email']{
  width: 506px;
}
.profile-group #subscription-length,
.profile-group #payment-method,
.profile-group #subscription-type{
  width: 495px;
}
.profile-group #amount{
  width: 470px;
}
.profile-group #phonee{
  width: 506px;
}
.profile-actions {
  display: flex;
  justify-content: space-between;
}

.profile-actions button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.profile-actions .cnclbtn{
  background-color: #7e807e;
}
.profile-actions .editbtn{
  background-color: #062b06;
}
.payment-container {
  background: white;
  padding: 3rem 7rem;
  border-radius: 8px;
  width: 700px;
  max-width: 100%;
}
