
.pricing-section {
  background-attachment:inherit;
  background-color: #e0e4deef;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 0 1%;
}

.pricing-container {
  align-self: center;
  display: flex;
  max-width: 435px;
  flex-direction: column;
}

.pricing-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Inter, sans-serif;
  color: #000;
  text-align: center;
}

.pricing-title {
  font-size: 45px;
  font-weight: 600;
  margin-top: 6rem;
}

.pricing-subtitle {
  font-size: 18px;
  font-weight: 400;
  margin-top: 9px;
}

.pricing-toggle {
  align-self: center;
  display: flex;
  margin-top: 28px;
  align-items: center;
  gap: 10px;
}

.toggle-label {
  color: #000;
  text-align: center;
  font: 600 18px Inter, sans-serif;
}

.toggle-switch {
  border-radius: 18px;
  background-color: #fff;
  display: flex;
  width: 68px;
  padding: 4px 6px;
  border: 1px solid #cbcbcb;
}

.toggle-slider {
  background-color: #103607de;
  border-radius: 50%;
  width: 28px;
  height: 28px;
}

.discount-badge {
  border-radius: 56px;
  color: #103607de;
  text-align: center;
  padding: 3px 12px;
  font: 300 9px Inter, sans-serif;
  border: 1px solid #103607de;
}

.pricing-plans {
  display: flex;
  margin-top: 7rem;
  width: 100%;
  align-items: stretch; /* Ensure cards stretch to the same height */
  gap: 38px;
  font-family: Inter, sans-serif;
  flex-wrap: wrap;
  justify-content: center;
}

.plan-card {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%; /* Allow cards to grow and shrink, with base size 100% */
  min-width: 240px;
  max-width: 390px;
  margin-bottom: 50px;
  border-radius: 35px;
  /* Adjust padding if needed */
}

.plan-content {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure footer is pushed to the bottom */
  width: 100%;
  height: 90%; /* Make sure it fills the container */
  padding: 35px 40px;
}

.plan-header {
  display: flex;
  flex-direction: column;
  align-items: start;
  font-weight: 600;
  padding: 0 50px 8px 0;
}

.plan-title {
  font-size: 18px;
}

.plan-subtitle {
  font-size: 12px;
  margin-top: 5px;
}

.plan-price {
  display: flex;
  margin-top: 20px;
  width: 100%;
  align-items: center;
  gap: 11px;
}

.price-amount {
  font-size: 48px;
  font-weight: 700;
}

.price-period {
  font-size: 12px;
  font-weight: 600;
}

.plan-cta {
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  font-size: 21px;
  font-weight: 600;
  text-align: center;
  padding: 10px;
}

.cta-button {
  flex: 1;
  border-radius: 5px;
  width: 75%;
  padding: 15px 0;
}

.plan-features {
  display: flex;
  align-items: start;
  justify-content: start;
  text-align: start;
  padding-right: 160px;
  margin-top: 20px;
  width: 100%;
  padding-right: 654px;
  margin-right: 9rem;
  flex-direction: column;
  font-size: 12px;
  font-weight: 600;
}

.feature-item {
  display: flex;
  width: 100%;
  margin-left: 0px;
  align-items: start;
  justify-content: start;
  gap: 13px;
  margin-top: 13px;
}

.feature-icon {
  align-content: start;
  justify-content: start;
  width: 5px;
}

.plan-footer {
  border-radius: 0 0 10px 10px;
  margin-top: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 23px 0;
}

.standard-plan {
  background-color: #127c71;
  color: #050505;
}

.standard-plan .plan-subtitle,
.standard-plan .price-period {
  color: #0e0404;
}

.standard-plan .cta-button {
  background-color: #fff;
  color: #103607de;
}

.standard-plan .plan-features {
  color: #7c7878;
}

.plan-footer {
  background-color: #103607de;
  color: #fff;
}

.free-plan,
.standard-plan,
.premium-plan {
  background-color: #127c71;
}

.free-plan .plan-title,
.premium-plan .plan-title,
.free-plan .price-amount,
.premium-plan .price-amount {
  font-size: 34px;
  color: #000;
}

.free-plan .plan-subtitle,
.premium-plan .plan-subtitle,
.free-plan .price-period,
.premium-plan .price-period,
.free-plan .plan-features,
.premium-plan .plan-features {
  text-align: justify;
  font-size: 13px;
  font-style: italic;
  color: #0e0606;
}

.free-plan .cta-button,
.premium-plan .cta-button {
  background-color: #fff;
  color: #103607de;
}

.free-plan .plan-footer,
.premium-plan .plan-footer {
  background-color: #f2f2f2;
  color: #000;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 991px) {
  .pricing-section {
      padding: 40px 20px;
  }

  .pricing-title {
      font-size: 40px;
  }

  .pricing-plans {
      margin-top: 40px;
  }

  .price-amount {
      font-size: 40px;
  }

  .plan-content, .plan-footer {
      padding-left: 20px;
      padding-right: 20px;
  }
}