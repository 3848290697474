.dashboard-container {
  background-color: #e0e4deef;
  padding: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.charts-row {
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.chart-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
  margin-top: 5px;
  margin-bottom: 25px;
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 60px;
  width: 400px;
  height: 400px;
}

.chart-card h3 {
  text-align: center;
  font-size: 19px;
  margin-bottom: 2px;
}

@media (max-width: 575px) {
  .chart-card{
    width: 300px;
    height: 300px;
  }
}


@media (min-width: 576px) and (max-width: 767px) {
  .chart-card{
    width: 350px;
    height: 350px;
  }
}

/* Large Tablets or Small Laptops (768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .chart-card{
    width: 380px;
    height: 380px;
  }
}