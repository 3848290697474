.sidebar_container {
  
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: #f9f9f9;
}


.sidebar-menu {
  padding-left: 4rem;
  justify-content: center;
  width: 250px;
  background-color: #ffffff;
  border-right: 1px solid #e5e5e5;
}
.sidebar {
  width: 220px;
  background-color: #ffffff;
  position: fixed;
  height: 100%;
  padding-top: 20px;
  border-right: 1px solid #e3e3e3;
}

.sidebar-menu h2 {
  margin-bottom: 20px;
  font-family: sans-serif;
  font-size: 36px;
  font-weight: bold;
  color: #333;
}

.sidebar-menu ul {
  font-family: sans-serif;
  list-style: none;
  padding: 0;
}
#logimg {
  display: block;
  width: 200px;
  height: 130px;
}
.sidebar-item {
  padding: 10px 0;
  font-size: 20px;
  color: #777;
  cursor: pointer;
  transition: color 0.3s ease;
}

.sidebar-item.active {
  font-weight: bold;
  color: #000;
}

.sidebar-item:hover {
  color: #333;
}

.sidebar-content {
  margin-top: 2rem;
  padding-left: 5rem;
  width: 60%;
  /* flex-grow: 1; */
}

.user_navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  padding: 0 4rem 0 0;
}
.user_navbar button{
  width:5rem;
  height:2rem;
  background-color: #0b2c08;
}
.user_page{
  width: 100%;
  margin-left: 1rem;
}
