.container{
    display: flex;
    height: 100vh;
    max-width: 800px;
    margin: auto;
    justify-content: center;
    align-items: center;
}
.feedback-form{
    border-radius: 10px; 
    width: 600px;
    height: 50vh;
    background-color: #809b90;
    justify-content: center;
    align-content: center;
}
h2 {
    font-family: 'Arial', sans-serif;
    margin-left: 25px;
}
.form-groupfee{
    padding-right: 45px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-direction: row;
    margin-left: 34px;  
}
.form-groupfee label{
    color: #333;
    font-weight: bold;
}
.form-control {
    
    width: 350px;
    border-radius: 10px;
}
#name{
    width: 380px;
    height: 35px;
}
#message
{
    padding: 24px;
}
.btn {
    background-color: #06200c;
    border-radius: 20px;
    align-self:flex-end;
    margin-left: 198px;
}

.list-group-item {
    border: none; 
    padding: 10px; 
}




