
.body-signup {
  font-family: sans-serif;
  background-color: #e0e4deef; /* Changed background color to a light gray */
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0;
  position: relative;
  overflow: hidden;
  
}

.container-signup {
  background-color: #ffffff; /* Changed container background color to white */
  padding: 20px 30px;
  border-radius: 35px;
  width: 80%;
  max-width: max-content;
  text-align: auto;
  margin: 20px auto;
  
}

.header-signup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.header-signup .logo {
  width: 80px;
  height: auto;
}

.top-right {
  font-size: 13px;
  color: #888;
  text-align: right;
}

.top-right a {
  text-decoration: none;
  color: gray;
  display: block;
  margin-bottom: 5px;
}

.top-right a:hover {
  text-decoration: underline;
}

h1 {
  font-size: 28px;
  color: #333;
  margin-bottom: 10px;
  text-align:center;
}

p {
  font-size: 12px;
  color: #666;
  margin-bottom: 15px;
}

.form-container {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

form {
  width: 100%;
}

.form-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.left, .right {
  width: 48%;
}

.right label {
  margin-left: 30px;
}
.left label{
  margin-left:3px;
}

label {
  display: block;
  text-align: start;
  font-weight: bold;
  margin-bottom: 4px;
  color: #999;
  margin-left: 0px;
}
.form-group .right label {
  display: block;
  text-align: start;
  font-weight: bold;
  margin-bottom: 4px;
  color: #999;
  margin-left: 0px;
}
#contactperson{
  width:350px;
}
#subscription-type ,
#subscription-length ,
#payment-method
{
  width: 380px;
  padding: 12px;
  border: 1px solid #333;
  border-radius: 6px;
  font-size: 12px;
}
#firstnamez,
#nameCompany
{
  width: 350px;
  padding: 12px;
  border: 1px solid #333;
  border-radius: 6px;
  font-size: 12px;
}

#lastnamez,
#contactperson1
{
  width: 350px;
  padding: 12px;
  border: 1px solid #333;
  border-radius: 6px;
  font-size: 12px;
}

#password3
{
  width: 380px;
  padding: 12px;
  border: 1px solid #333;
  border-radius: 6px;
  font-size: 12px;
}
#amount 
{
  width: 350px;
  padding: 12px;
  border: 1px solid #333;
  border-radius: 6px;
  font-size: 12px;
}
#errorsta{
  color:red;
}
.form-group .left {
  position: relative;
  display: inline-block;
  margin-left:20px;
  z-index:2;
}


input[type="password"] {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  max-width: 400px; 
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index:2;
}


.hover-list {
  display: none;
  position: absolute;
  bottom: 100%; 
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 3; 
  max-height: 200px; 
  overflow-y: auto; 
}


.form-group .left:hover .hover-list {
  display: block;
}


.hover-list li {
  padding: 8px 10px;
  font-size: 14px;
  cursor: pointer;
  
  margin-left:15px;
  z-index:3;
}

.hover-list li:hover {
  background-color: #f0f0f0;
}

#password2  
{
  width: 380px;
  padding: 12px;
  border: 1px solid #333;
  border-radius: 6px;
  font-size: 12px;
}

#emaill
{
  width: 350px;
  padding: 12px;
  border: 1px solid #333;
  border-radius: 6px;
  font-size: 12px;
}
#phone2
{
  width: 350px;
  padding: 12px;
  border: 1px solid #050505;
  border-radius: 6px;
  font-size: 12px;
}

.password-group {
  position: relative;
}

.password-group .toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 12px;
  color: #888;
}

small {
  font-size: 10px;
  color: #888;
  margin-top: 2px;
  display: block;
}
#logimg {
  display: block;
  width: 200px;
  height: auto;
}

.password-hints {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-top: 8px;
  font-size: 10px;
  color: #888;
}

.password-hints li {
  margin-bottom: 4px;
}

.termssign {
  margin-top: 15px;
  font-size: 14px;
  color: #666;
  text-align: center;
}

.termssign a {
  color: #103607de;
  text-decoration: none;
}

.terms a:hover {
  text-decoration: underline;
}

.signupbtn {
  width: 400px;
  padding: 12px;
  background-color: #127c71;
  color: #fff;
  border: none;
  border-radius: 15px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
  margin-left:200px;
}

.signupbtn:hover {
  background-color: #6ecac1;
}

.terms-containers {
  text-align: center;
  font-size: 10px;
  color: #666;
  margin-top: 10px;
}

@media (max-width: 575px) {
  .container-signup {
    padding: 15px;
    padding-right:50px;
    padding-left:15px;
  
    input[type="text"],
    input[type="email"]
    {
      max-width:180%;
    }
    .signupbtn{
      width:200px;
      margin-left:85px;
    }
    input[type="password"]{
      max-width:195%;
    }
    select{
      max-width:195%;
    }
    label{
      text-align:start;
      margin-left:0px;
    }
    #contactperson{
      width:1080%;
      margin-left:20px;

    }
    .right label{
      margin-left: 20px;
      padding:5px;
    }
    
    li{
      display:none;
    }
   
    small{
      display:none;
    
  }
  #lastnamez{
    margin-left:20px;
  }
  #phone2{
    margin-left:20px;
  }
  #password2{
    margin-left:20px;
  }
  #subscription-length{
    margin-left:20px;
  }
  #amount{
    margin-left:20px;
  }
  
   
  
 
  }
  .form-group {
    flex-direction: column;
    
  }
  
  .form-group .left, .form-group .right {
    -width: 100%;
    
  }
  
  .header-signup {
    flex-direction: column;
    align-items: center;
  }
  
  .top-right {
    margin-top: 10px;
    text-align: center;
  }
}

/* @media (max-width: 767px) {
  .header-signup .logo {
    max-width: 100px;
  }
  input[type="text"]{
    color:black;
  }
} */

/* @media (min-width: 768px) and (max-width: 991px) {
  .container-signup {
    padding: 30px;
  }
  input[type="text"]{
    width:30px;
  }
  input[type="password"]{
    width:10px;
  }
  #contactperson{
    width:350px;
  }
  label{
    margin-left:0px;
  }
  .form-group{
    justify-content:space-between;
  }
} */
@media (min-width: 576px) and (max-width: 767px) {
  .container-signup {
    padding: 20px;
  }

  input[type="text"],
  
  input[type="email"] {
    max-width: 80%;
  }
  input[type="password"]{
    max-width: 85%;
  }
  select{
    max-width: 85%;
  }
  #contactperson {
    max-width: 80%;
  }
  
  .signupbtn {
    width: 50%;
  }

  .form-group {
    display: flex;
    flex-direction: row;
  }

  .form-group .left,
  .form-group .right {
    width: 48%;
  }

  .header-signup {
    flex-direction: row;
    justify-content: space-between;
  }

  .top-right {
    margin-top: 0;
    text-align: right;
  }
  
}

/* Large Tablets or Small Laptops (768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .container-signup {
    padding: 30px;
  }

  input[type="text"],
  
  input[type="email"]{
    max-width: 80%;
  }
  input[type="password"]{
    max-width: 85%;
  }
  select{
    max-width: 85%;
  }
  #contactperson {
    max-width: 80%;
  }

  .signupbtn {
    width: 30%;
  }
  .left label{
    margin-left:3px;
  }
  .left small{
    margin-left:40px;
  }
  .form-group {
    display: flex;
    flex-direction: row;
  }

  .form-group .left,
  .form-group .right {
    width: 48%;
  }

  .header-signup {
    flex-direction: row;
    justify-content: space-between;
  }

  .top-right {
    margin-top: 0;
    text-align: right;
  }
}