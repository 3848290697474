
.obfuscatebtn{
    background-color: #127c71;
    display: flex;
    width: 400px;
    border-style: hidden;
}


#projectname{
  padding: 2px;
  width: 500px;
  margin-top: 10px;
  border-radius: 5px;
  align-content: left;
  height: 25px;
}
.form-groupup{
  display: flex;
  padding: 2px;
  flex-direction: row;
  margin-bottom: 20px;
  gap: 0;
  width: 400px;
}
.form-groupup label{
  font-size: 19px;
  margin-left: 0;
}
.select_frmt{
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: start;
  justify-content: start;
}
.select_frmt label{
  align-content: left;
  margin-left:0;
}
.select_frmt select{
  width: 400px;
}
.checkbox{
  display: inline-flex;
  flex-direction: row;
}
.checkbox label{
  color: #333;
  font-size: 15px;
  margin-right: 95px;
}
.selectDll{
  display: flex;
  flex-direction: column;
  
  margin-left: 60px;
}
.selectDll select{
  width: 342px;
  border-radius: 5px;
  height: 30px;
}
.selectDll #formt{
  margin-bottom: 9px;
}
.file-list{
  width: 400px;
  background-color: #fff;
  flex-direction: column;
}

.imgslide img{
  width: 300px;
  border-radius: 6px;
  margin-top: 0;
}

#file{
  width: 400px;
  align-items: center;
  background-color:#127c71 ;
  justify-content: center;
}
@media (max-width: 1024px) {
  .obfuscatebtn {
    width: 400px;
    margin-left: 0;
  }

  .form-group1 {
    flex-direction: column;
    align-items: flex-start;
    width: 400px;
  }
}

/* Mobile screens */
@media (max-width: 600px) {
  .obfuscatebtn {
    width: 400px;
    padding: 12px;
    font-size: 0.9rem;
    margin-left:95px;
    
  }

  .form-group1 {
    flex-direction: column;
    align-items: flex-start;
    width: 400px;
  }
}