
.fancy-hr {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    position: relative;
}

.fancy-hr::before,
.fancy-hr::after {
    content: '';
    flex: 1;
    height: 2px;
    background: linear-gradient(to right, transparent, #3498db, transparent);
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.fancy-hr::before {
    margin-right: 10px;
}

.fancy-hr::after {
    margin-left: 10px;
}

.dots {
    display: flex;
    gap: 5px;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: linear-gradient(45deg, #3498db, #9b59b6);
}