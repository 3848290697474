
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  box-sizing: border-box;
}


.forgot-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #f7f9fc; 
}


.body-forgot {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
}


.container-forgot {
  width: 90%;
  max-width: 500px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}


.logo-forgot {
  margin-top:10px;
  margin-bottom: 20px;
  margin-right:1110px;
}

.logo-forgot img.logo {
  width: 100px;
  height: auto;
}


.forgot-container {
  margin-top: 10px;
}

.forgot-container form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}


.forgot-group {
  display: flex;
  flex-direction: column;
}

.forgot-group label {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 8px;
  margin-left:64px;
  color: #333;
  align-self: center;
}


form .forgot-group input[type="email"] {
  width: 350px;
  padding: 10px;
  font-size: 14px;
  border: 2px solid #555 !important; 
  border-radius:0;
  transition: border-color 0.3s;
}

.forgot-group input[type="email"]:focus {
  outline: none;
}

/* Small Text */
.forgot-group small {
  font-size: 12px;
  color: #555;
  margin-left: 0px;
  margin-top: 10px;
}

/* Button */
#sendcode {
  background-color: #0e350d;
  color: white;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  width:200px;
  margin-top:20px;
  margin-left:14px;
}

button#sendcode:hover {
  background-color: #1d8b4f;
}

@media (max-width: 600px) {
  .forgot-group label{
    max-width:100%;
    font-size:20px;
  }
  #emailforgot{
    width:300px;
  }
  .forgot-group small{
    margin-left:35px;
  }
  
  .herof-section{
    overflow-x:hidden;
  }
 button #sendcode{
  margin-left:35px;
 }
  .forgot-container{
    max-width:50%;
    height:50%;
  }
  
}
@media (min-width: 601px) and (max-width: 1024px){
  .forgot-group label{
    max-width:100%;
    font-size:20px;
  }
  #emailforgot{
    width:300px;
  }
  .forgot-group small{
    margin-left:35px;
  }
  
  .forgot-section{
    overflow-x:hidden;
  }
 button #sendcode{
  margin-left:35px;
 }
  .forgot-container{
    max-width:50%;
    height:50%;
    margin-left:150px;
  }
  .logo-login{
    display:none;
  }
}
      
  

