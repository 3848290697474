.stats-section {
  height: 130vh;
  /* background-color: #12354d; */
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0 0;
}

.stats_container {
  padding: 1.5rem 1rem 0 3rem;
  margin: 0;
  /* margin-bottom: 3rem; */
  width: 55rem;
  height: 45rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  background-image: url("../../assets/curved_grey_light.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.we_are_happy {
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  /* background-color: #2f4356; */
  height: 16rem;
  width: 25rem;
}
.left_arrow {
  padding: 1rem;
  width: 1.8rem;
  height: 2rem;
}
.right_arrow {
  padding: 1rem;
  width: 2.8rem;
  height: 3rem;
}

.inside_column {
  height: 15rem;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.inside_row {
  margin-left: 3rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.stats_container2 {
  margin: 1rem 0;
  padding: 0rem;
  width: 55rem;
  height: 25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  background-image: url("../../assets/curved_grey_r_light.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.happy {
  height: 70vh;
  display: flex;
  justify-content: end;
}
.stat h3 {
  color: #f69205;
  font-size: 4rem;
  margin-bottom: 10px;
}

.stat p {
  width: 200px;
  font-size: 2rem;
}

.happy_cients {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 1rem;
}

.happy_cients h1 {
  margin-right: 2rem;
  font-size: 4rem;
}

.stars {
  width: 10rem;
  height: 1.5rem;
  padding-bottom: 3rem;
  padding-top: 1rem;
}

.inside_column h3 {
  padding: 0;
  margin: 1rem 0 0 0;
  color: black;
}
.inside_column h4 {
  padding: 0;
  margin: 0 0.8rem;
  font-weight: 200;
  color: #868686;
}
.we_are_happy p {
  font-size: 1.5rem;
}
