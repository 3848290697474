.container_upload {
  background-color: #e0e4deef;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  scroll-behavior: smooth;
}

.main-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #170f49;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 0;
}

.sub-title {
  display: flex;
  justify-content: center;
  color: #6f6c90;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 30px;
}

.tab-wrapper {
  background-color: #ffffff;
  border: 2px solid #eff0f6;
  border-radius: 34px;
  box-shadow: 0 5px 16px rgba(8, 15, 52, 0.15);
  width: 700px;
  padding: 20px 50px;
  margin-bottom: 54px;
}

.tabs {
  display: flex;
  justify-content: start;
  margin-left: 10px;
  padding-left: 100px;
  margin-bottom: 20px;
}

.tab {
  text-decoration: none;
  color: #6f6c90;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 15px;
  cursor: pointer;
}

.tab.active, .tab:focus {
  color: #000000;
  font-weight: 700;
}

hr {
  border: 0;
  border-top: 2px solid #eff0f6;
  margin: 15px 0;
}

.drag-and-drop-wrapper {
  text-align: left;
  margin-bottom: 30px;
}

.drag-and-drop {
  width: 100%;
  height: 350px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #999;
  font-size: 16px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.drag-and-drop.success {
  background-color: #d4edda;
  color: #155724;
}

.file-list {
  margin-top: 20px;
  list-style-type: none;
  padding-left: 0;
}

.file-item {
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.file-item span {
  color: #000000;
  margin-right: 10px;
}

.delete-icon {
  color: #dc3545;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.success-icon {
  color: #28a745;
  font-size: 18px;
  display: none;
}

.submit-button {
  width: 30%;
  padding: 12px;
  background-color: #f69205;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .main-title {
    font-size: 30px;
  }

  .sub-title {
    font-size: 18px;
  }

  .tab-wrapper {
    padding: 20px;
    width: 90%;
  }

  .drag-and-drop {
    height: 300px;
  }

  .submit-button {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .main-title {
    font-size: 22px;
  }

  .sub-title {
    font-size: 16px;
  }

  .tab-wrapper {
    padding: 15px;
    width: 95%;
  }

  .tabs {
    flex-direction: column;
    text-align:center;
  }

  .tab {
    padding: 8px;
  }

  .drag-and-drop {
    height: 250px;
  }

  .submit-button {
    width: 70%;
  }
}
