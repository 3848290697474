.home {
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  color: #fff;
  background-attachment:inherit;
  background-color: #afb4aec2;
  scroll-behavior: smooth;
  overflow: hidden;
  ;
}



button {
  background-color: #12c9d9;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.describe{
  max-height: 26%;
}
.service{
  max-height: 25%;
  
}
.pricing{
  max-height: 28%;
}
.hitw{
  max-height: 14%;
}
.footer{
  max-height: 13%;
}