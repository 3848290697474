.bodylogin {
  font-family: "Helvetica Neue", Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #e0e4deef;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-decoration:wavy;
  position: relative;
}

.containerlogin {
  display: flex;
  max-width: 900px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 75px;
  align-items: flex-start;
  background-color: #fff;
  position: relative;
  pointer-events: none; /* Disable pointer events on the entire container */
}

.leftsection, .rightsection {
  flex: 1;
  
  pointer-events: auto; /* Enable pointer events only on sections with interactive content */
}
.leftsection{
  border-radius: 75px;
  background-color: #fff;
}
.logo-login {
  margin-bottom: 20px;
}

#logimg {
  display: block;
  width: 150px;
  height: 20px;
}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #344634;
}

p, a, .plan-details p, .plan-details ul li, label {
  color: #74887a;
  font-size: 106px;
}

a {
  color: gray;
  text-decoration: none;
}
.fgbtn, .signupb {
  color: gray;
  text-decoration: none;
  background-color:inherit ;
}

form {
  margin-bottom: 10px; /* Decreased the margin below the form */
}

label {
  margin-bottom: 5px;
  display: block;
}


#username2
{
  width: 350px;
  padding: 10px;
  margin-bottom: 10px; /* Decreased the margin below the input fields */
  border: 1px solid #070707;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
  pointer-events: auto;  
}

#passwordlo
{
  width: 350px;
  padding: 10px;
  margin-bottom: 10px; /* Decreased the margin below the input fields */
  border: 1px solid #070707;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
  pointer-events: auto;  
}
#error{
  color: red;
}
#btnlogin{
  width: 86%;
  padding: 12px;
  background-color: #127c71;
  color: #fff;
  border: none;
  border-radius: 25px;
  font-size: 14px;
  cursor: pointer;
  pointer-events: auto; /* Enable pointer events on button-logins */
}

.forgot-password {
  font-size: 14px;
  text-align: left;
  margin-bottom: 0; /* Decreased the margin below the "Forgot password" link */
}

.bottom-right {
  font-size: 14px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0; /* Added margin-top to control space above this section */
}

.bottom-right p {
  margin-right: 5px;
}

.right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 50px;
}

.right-section img {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.plan-details {
  margin-top: 0;
  text-align: left;
}

.plan-details p {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
}

.plan-details ul {
  list-style-type: none;
  padding-left: 15px;
}

.plan-details ul li {
  margin-bottom: 8px;
}
@media screen and (max-width: 768px) {
  .containerlogin {
    flex-direction: column; /* Stack sections vertically on smaller screens */
    padding: 15px;
  }

  .leftsection, .rightsection {
    flex: none;
    width: 100%; /* Make both sections take up full width */
    margin-bottom: 20px; /* Add space between sections */
  }

  .logo-login {
    text-align: center;
    margin-bottom: 15px;
  }

  #logimg {
    width: 150px; /* Adjust logo size */
    height: auto;
  }

  h1 {
    font-size: 15px; 
    text-align:center;
  }

  #username2, #passwordlo, #btnlogin {
    width: 100%; /* Full width for inputs and button */
    max-width: 100%;
  }

  .rightsection {
    margin-left: 0;
  }

  .plan-details p {
    font-size: 16px;
  }

  .plan-details ul li {
    font-size: 14px;
  }

  
  .rightsection img {
    display: none; 
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 18px; 
    text-align:center;
  }

  #username2, #passwordlo {
    font-size: 14px;
  }

  #btnlogin {
    font-size: 12px; 
    width:150px;
    margin-left:100px;
  }

  .plan-details p {
    font-size: 14px; 
  }
}